import React from "react";

import CallToAction from "../../components/CallToAction/CallToAction";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";
import SettlementGrid from "../../components/Grid/Settlements/SettlementGrid";


const SettlementsPage = () => {
  return (
    <Layout
      title="Settlements in Progress"
      description="The following cases have been settled and their settlements have been Court-approved. 
      The settlements in these cases are currently in the process of being administered."
    >
      <Header
        colorScheme="light"
      />

      <Hero
        title="Settlements in Progress"
        subtitle="The following cases have been settled and their settlements have been Court-approved. 
        The settlements in these cases are currently in the process of being administered. Click 
        on the below links to obtain further information about the settlement in that action."
        contactInfo={ false }
        colorScheme="light"
      />

      <SettlementGrid
        heading="Settled Actions"
      />

      <CallToAction />

      <Footer />
    </Layout>
  );
}

export default SettlementsPage;